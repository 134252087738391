import React, { useContext, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Logo from "../assets/logo.png";
import Container from "react-bootstrap/Container";
import "../styles/ouroninas.css";
import NavDropdown from "react-bootstrap/NavDropdown";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button } from "react-bootstrap";
import { CartContext } from "./context/shopp/cartShoppService";
import { Link, useNavigate } from "react-router-dom";
import { logoutClienteStorage } from "../utils/localStorage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { cpfObservable } from "../services/modules/cliente/clienteObservable";
import manualGarimpeiro from "../assets/docs/manual-garimpeiro.pdf";
import { env } from "../services/env";

function Header() {
  const { toogleCArt } = useContext(CartContext);
  const [expanded, setExpanded] = useState(false);
  const [isCliente, setCliente] = useState(null);
  const navigate = useNavigate();
  const handleLinkClick = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  const logout = () => {
    logoutClienteStorage();
    navigate(
      "/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/dados-pessoais"
    );
  };

  useEffect(() => {
    const subscription = cpfObservable.subscribe((newCpf) => {
      if (newCpf && newCpf.length > 0) {
        setCliente(newCpf);
      } else setCliente(null);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <Navbar expand="lg" sticky="top" className="header" expanded={expanded}>
      <Container>
        <Navbar.Brand as={Link} rel="canonical" to="/om">
          <Image src={Logo} className="logo" title="Ouro Minas" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="my-2 my-lg-0">
            <NavDropdown
              className="links navDrop"
              title="SOBRE A OM +"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                as={Link}
                to="/om/quem-somos"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Quem Somos
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/credibilidade"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Credibilidade
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/grupo-om"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Grupo OM
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/projetos-sociais"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Projetos Sociais
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/planta-arvore"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Programa Plante Árvore
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/compliance"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Compliance
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/demonstracoes-contabeis"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Demonstrações Contábeis
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className="links navDrop"
              title="OURO +"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/ouro/origem-do-ouro"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Origem do Ouro
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/ouro/ouro-para-voce"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Ouro para Você
              </NavDropdown.Item>
              {/* <NavDropdown.Item
                href="https://www.ourominasapp.com/"
                rel="noopener noreferrer"
                target="_blank"
                className="text-capitalize"
              >
                Ouro Digital (App)
              </NavDropdown.Item> */}
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/ouro/sua-empresa"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Ouro para sua Empresa
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/ouro/b3"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Ouro B3
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/ouro/cartao-ouro"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Cartão OuroFácil
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/ouro/piramide-de-ouro"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Pirâmide de Ouro
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/ouro/vender-meu-ouro"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Vender meu ouro
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/ouro/premiacao-barra-ouro"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Premiação Certificado Barra de Ouro
              </NavDropdown.Item>
              <NavDropdown
                title="Exportação"
                id="collasible-nav-dropdown"
                className="text-capitalize navExport"
              >
                <NavDropdown.Item
                  as={Link}
                  rel="canonical"
                  to="/om/ouro/exportacao"
                  className="text-capitalize"
                  onClick={handleLinkClick}
                >
                  Português
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={Link}
                  rel="canonical"
                  to="/om/ouro/export"
                  className="text-capitalize"
                  onClick={handleLinkClick}
                >
                  English
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/ouro/curiosidades-ouro"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Curiosidade sobre o Ouro
              </NavDropdown.Item>
              <NavDropdown.Item
                href={manualGarimpeiro}
                rel="noopener noreferrer"
                target="_blank"
                className="text-capitalize"
              >
                Gibi do Garimpeiro
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className="links navDrop"
              title="Câmbio +"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/cambio/moedas-estrangeiras"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Moedas Estrangeiras
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/cambio/cartao-pre-pago"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Cartão Pré-Pago
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://meu.brasilprepagos.com.br/bpp"
                rel="noopener noreferrer"
                target="_blank"
                className="text-capitalize"
              >
                Cartão Pré-pago (saldo e extrato)
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/cambio/remessa-internacional"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Remessa Internacional
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/cambio/moneyGram"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                MoneyGram
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/cambio/more-payment"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                More Payment
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/cambio/vender-minha-moeda"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Vender Minha Moeda Estrangeira
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/cambio/curiosidades-cambio"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Curiosidades sobre o Câmbio
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              as={Link}
              rel="canonical"
              to="/om/lojas"
              className="links"
              onClick={handleLinkClick}
            >
              LOJAS
            </Nav.Link>

            <NavDropdown
              className="links navDrop"
              title="AUTORIZADO +"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                as={Link}
                rel="canonical"
                to="/om/autorizado/seja-um-autorizado"
                className="text-capitalize"
                onClick={handleLinkClick}
              >
                Seja um Autorizado
              </NavDropdown.Item>
              <NavDropdown
                //alignRight
                title="Modelos de Negócio"
                id="collasible-nav-dropdown"
                className="text-capitalize navExport"
              >
                <NavDropdown.Item
                  as={Link}
                  rel="canonical"
                  to="/om/autorizado/modelo-negocio/correspondente-cambial"
                  className="text-capitalize border-bottom"
                  onClick={handleLinkClick}
                >
                  Correspondente Cambial
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  rel="canonical"
                  to="/om/autorizado/modelo-negocio/indicador-cambial"
                  className="text-capitalize border-bottom"
                  onClick={handleLinkClick}
                >
                  Indicador Cambial
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  rel="canonical"
                  to="/om/autorizado/modelo-negocio/indicador-digital"
                  className="text-capitalize border-bottom"
                  onClick={handleLinkClick}
                >
                  Indicador Digital
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  rel="canonical"
                  to="/om/autorizado/modelo-negocio/indicador-financeiro"
                  className="text-capitalize"
                  onClick={handleLinkClick}
                >
                  Indicador Ativo Financeiro
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown.Item
                href="https://www.travelcash.com.br/Login.aspx"
                rel="noopener noreferrer"
                target="_blank"
                className="text-capitalize"
              >
                Acesso Autorizados
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              as={Link}
              rel="canonical"
              to="/om/atendimento"
              className="links"
              onClick={handleLinkClick}
            >
              ATENDIMENTO
            </Nav.Link>

            <Nav.Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://blog.ourominas.com/"
              className="links"
              onClick={handleLinkClick}
            >
              BLOG
            </Nav.Link>

            {!env.isProd && (
              <>
                {isCliente && (
                  <NavDropdown
                    className="links navDrop"
                    title={
                      <>
                        <AccountCircleIcon />
                      </>
                    }
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item
                      as={Link}
                      rel="canonical"
                      to="/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido"
                      className="text-capitalize"
                      onClick={handleLinkClick}
                    >
                      Carrinho
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      rel="canonical"
                      to="/om/painel/perfil"
                      className="text-capitalize"
                      onClick={handleLinkClick}
                    >
                      Minha conta
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      rel="canonical"
                      to="om/painel/perfil/dados-pessoais"
                      className="text-capitalize"
                      onClick={handleLinkClick}
                    >
                      Dados Pessoais
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      rel="canonical"
                      to="om/painel/perfil/endereco"
                      className="text-capitalize"
                      onClick={handleLinkClick}
                    >
                      Endereço
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      rel="canonical"
                      className="text-capitalize"
                      onClick={logout}
                    >
                      Sair
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </>
            )}

            <Nav.Link href="#blog" className="links">
              <Button variant="link" onClick={toogleCArt}>
                <ShoppingCartIcon className="iconShopp" />
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
