import React, { useEffect, useState } from 'react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import './menuPedido.css';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import Temporizador from '../temporizador/temporizador';
import { cpfObservable } from '../../../services/modules/cliente/clienteObservable';

const styleForButton = {
  width: '1rem',
  justifyContent: 'center',
  marginRight: '5px',
};

const styleBreadcrumbs = {
  margin: '10px 5px ',
  display: 'block',

  '@media (min-width: 500px)': {
    margin: '20px',
  },

  '@media (min-width: 768px)': {
    display: 'none',
  },
};

export default function MenuPedido() {
  const navigate = useNavigate();
  const [cliente, setCliente] = useState();
  function handleClick(event, route) {
    event.preventDefault();
    window.scrollTo(0,0)
    navigate(route);
  }
  const breadcrumbs = [
    <Link
      underline="none"
      key="3"
      color="inherit"
      onClick={(e) =>
        handleClick(
          e,
          '/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/dados-pessoais'
        )
      }
      className="breadcrumb-item"
    >
      Dados Pessoais
    </Link>,
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={(e) =>
        handleClick(
          e,
          '/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido'
        )
      }
      className="breadcrumb-item"
    >
      Carrinho de Compras
    </Link>,
    <Link
      underline="none"
      key="2"
      color="inherit"
      onClick={(e) =>
        handleClick(
          e,
          '/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/formas-entrega'
        )
      }
      className="breadcrumb-item"
    >
      Formas de Entrega
    </Link>,
    <Link
      underline="none"
      key="4"
      color="inherit"
      href=""
      onClick={(e) =>
        handleClick(
          e,
          '/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/pagamento'
        )
      }
      className="breadcrumb-item"
    >
      Pagamento
    </Link>,
  ];

  useEffect(() => {
    const subscription = cpfObservable.subscribe(newCpf => {
      if(newCpf && newCpf.length > 0){
        setCliente(newCpf);
      }else setCliente(null);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <Temporizador />
      {/* <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={styleBreadcrumbs}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack> */}

      {/* ASSIM NO DESKTOP */}

      <article className="menuU">
      {!cliente && <div className="etapasS">
          <button
            className="button-menuU"

          >
            <p className="button">Dados Pessoais</p>
            <div>
              <PersonIcon style={styleForButton} />
              Insira seus dados
            </div>
          </button>
        </div>}
        <div className="etapasS">
          <button
            className="button-menuU"

          >
            <p className="button">Carrinho de Compras</p>
            <div>
              <AddShoppingCartIcon style={styleForButton} />
              Selecione os itens
            </div>
          </button>
        </div>
        <div className="etapasS">
          <button
            className="button-menuU"

          >
            <p className="button">Formas de Entrega</p>
            <div>
              <CalendarTodayIcon style={styleForButton} />
              Agende sua entrega
            </div>
          </button>
        </div>

        <div className="etapasS">
          <button
            className="button-menuU"

          >
            <p className="button">Pagamento</p>
            <div>
              <CheckIcon style={styleForButton} />
              Realize o pagamento
            </div>
          </button>
        </div>
      </article>
    </>
  );
}
