import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { getOperacaoByID } from "../../services/modules/cliente/operacaoService";
import { PageLoading } from "../../components/Loading/Loading";
import CountryFlags from "../../utils/countryFlags";
import { ReturnName } from "../../utils/coins";
import { toBRL } from "../../utils/regex";

export default function PedidoItem() {
  const [load, setLoad] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (id) {
      setLoad(true);
      getOperacaoByID(parseInt(id))
        .then(setData)
        .catch((e) => console.log(e))
        .finally(() => setLoad(false));
    }
  }, [id]);
  return (
    <Container>
      {load && <PageLoading />}
      <h2>Detalhes do Pedido</h2>
      {!data && !load && <p>Não foi possível buscar o seu pedido.</p>}

      {data && (
        <>
          <CountryFlags cod={data.dadosOperacao.tipo} />
          <h5> {ReturnName(data.dadosOperacao.tipo)}</h5>
          {data.dadosOperacao.status === "W" ? (
            <h6>Pedido em andamento</h6>
          ) : data.dadosOperacao.status === "A" ? (
            <h6>Pedido concluído</h6>
          ) : data.dadosOperacao.status === "N" ? (
            <h6>Pedido cancelado</h6>
          ) : null}
          <BoxTables>
            <div>
            <Table>
              <summary>Dados do Cliente</summary>
              <div>
                <p>Nome/Razão:</p>
                <p>{data.dadosCliente.nome}</p>
              </div>
              <div>
                <p>CPF/CNPJ:</p>
                <p>{data.dadosCliente.documento}</p>
              </div>
              <div>
                <p>Endereço:</p>
                <p>
                  {data.dadosCliente.endereco},{" "}
                  {data.dadosCliente.numeroEndereco}{" "}
                  {data.dadosCliente.complemento}{" "}
                </p>
              </div>

              <div>
                <p>Bairro:</p>
                <p>{data.dadosCliente.bairro}</p>
              </div>

              <div>
                <p>Cidade/UF:</p>
                <p>{data.dadosCliente.cidade}</p>
              </div>
              <div>
                <p>Contratado:</p>
                <p>{data.dadosContratado.nome}</p>
              </div>
              <div>
                <p>Forma de Pagamento:</p>
                <p>{data.dadosOperacao.formaPagamento}</p>
              </div>
              <div>
                <p>Observação:</p>
                <p>{data.dadosOperacao.observacao}</p>
              </div>
            </Table>
            </div>
            <div>
            <Table>
              <summary>Dados da Solicitação</summary>
              <div>
                <p>Quantidade:</p>
                <p>
                  {data.dadosOperacao.tipo} {data.dadosOperacao.qtde}
                </p>
              </div>
              <div>
                <p>Taxa Cambial:</p>
                <p>{toBRL(data.dadosOperacao.taxa, 6)}</p>
              </div>
              <div>
                <p>Valor da Moeda Nacional (S/IOF):</p>
                <p>{toBRL(data.dadosOperacao.valorParcial)}</p>
              </div>

              <div>
                <p>IOF:</p>
                <p>{toBRL(data.dadosOperacao.iof)}</p>
              </div>

              <div>
                <p>Tarifa:</p>
                <p>{toBRL(data.dadosOperacao.remessa)}</p>
              </div>
              <div>
                <p>
                  <b>Valor efetivo total (VET):</b>
                </p>
                <p>
                  <b>{toBRL(data.dadosOperacao.total)}</b>
                </p>
              </div>
            </Table></div>
          </BoxTables>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    margin-top: 20px;
    width: 150px;
  }

  > h5 {
    color: rgb(0, 65, 51);
  }
  min-height: 600px;

  padding: 20px 0;
`;

const BoxTables = styled.div`
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
  place-content: center;
`;
const Table = styled.details`
  display: flex;
  flex-direction: column;
  border: 3px solid;
  padding: 10px;
  @media(min-width: 320px){
  width: 90vw;
  }
  @media(min-width: 520px){
  width: 450px;
  }
  margin-top: 20px;
  > summary {
    color: #204233;
  }

  div:nth-of-type(n) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    border-bottom: 1px solid;
    > p {
      margin: 0;
    }
    p:nth-of-type(2) {
      text-align: end;
      color: #204233;
    }


  }
    div:last-child {
      border: 0;
    }
`;
